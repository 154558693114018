var x = require('prop-types');

var array = x.array,
    bool = x.bool,
    func = x.func,
    number = x.number,
    object = x.object,
    string = x.string,
    symbol = x.symbol,
    any = x.any,
    arrayOf = x.arrayOf,
    element = x.element,
    elementType = x.elementType,
    instanceOf = x.instanceOf,
    node = x.node,
    objectOf = x.objectOf,
    oneOf = x.oneOf,
    oneOfType = x.oneOfType,
    shape = x.shape,
    exact = x.exact,
    checkPropTypes = x.checkPropTypes,
    resetWarningCache = x.resetWarningCache,
    PropTypes = x.PropTypes;
var __esModule = true;
export { __esModule, x as default, array, bool, func, number, object, string, symbol, any, arrayOf, element, elementType, instanceOf, node, objectOf, oneOf, oneOfType, shape, exact, checkPropTypes, resetWarningCache, PropTypes };